import 'regenerator-runtime/runtime'

import Rails from '@rails/ujs'
import * as ActiveStorage from '@rails/activestorage'
import '@hotwired/turbo-rails'
import './controllers'

import 'trix'
import '@rails/actiontext'

import RoleModelModal from './helpers/rolemodel-modal'
import RoleModelConfirm from './helpers/rolemodel-confirm'

import './initializers/frame_missing_handler'
import './initializers/honeybadger'

Rails.start()
ActiveStorage.start()

const onPageLoad = () => {
  // RoleModelPanel.init()
  RoleModelModal.init()
  RoleModelConfirm.init()
}

document.addEventListener('turbo:load', onPageLoad)
document.addEventListener('rolemodel:pageLoad', onPageLoad)
