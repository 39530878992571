import { Controller } from '@hotwired/stimulus'
import * as Turbo from '@hotwired/turbo'
import csrfToken from '../lib/csrfToken'

/*
 * A form component to update the live manufacturer sale form by fetching an additional row for the line items table
 * NOTE: easily could be refactored into a generic controller
 */
export default class extends Controller {
  async displayBlock(e) {
    e.preventDefault()
    e.stopPropagation()
    e.stopImmediatePropagation()

    if (e.params.type && e.target.value) {
      const streamMessage = await this.#fetchStreamMessage(`/${e.params.type}/${e.target.value}/display_line_items_form`)
      await Turbo.renderStreamMessage(streamMessage)
    }
  }

  async addRow(e) {
    e.preventDefault()
    e.stopPropagation()

    if (!e.params.type) {
      return
    }

    const streamMessage = await this.#fetchStreamMessage(`/${e.params.type}/add_line_item_form`)
    await Turbo.renderStreamMessage(streamMessage)
  }

  async #fetchStreamMessage(path) {
    const response = await fetch(
      path,
      {
        method: 'POST',
        headers: {
          'Accept': 'text/vnd.turbo-stream.html',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken(),
        },
      },
    )
    return response.text()
  }
}
