import currency from 'currency.js'

export default class ContractCalculator {
  constructor(price, deliveryCharge, setupFee, months, taxRate, ldwRequested, depositRequired, state) {
    this.price = currency(price)
    this.deliveryCharge = currency(deliveryCharge)
    this.setupFee = currency(setupFee)
    this.months = months
    this.taxRate = taxRate
    this.ldwRequested = ldwRequested
    this.depositRequired = depositRequired
    this.state = state
  }

  results(numericDown = 0) {
    const down = currency(numericDown)

    const nominalInitialPayment = this._initial()

    const excessPayment = down.intValue > nominalInitialPayment.intValue

    let initialPayment, cra
    if (excessPayment) {
      initialPayment = down
      cra = down.subtract(this._initial(down)).add(this._cra(down))
    } else {
      initialPayment = nominalInitialPayment
      cra = currency(0)
    }

    return {
      price: this.price,
      deliveryCharge: this.deliveryCharge,
      setupFee: this.setupFee,
      totalPaid: this._totalPaid(down),
      monthlyPayment: this._monthly(down),
      initialPayment,
      securityDeposit: this._securityDeposit(down),
      monthlyRental: this._monthlyRental(down),
      initialSalesTax: this._initialSalesTax(down),
      monthlySalesTax: this._monthlySalesTax(down),
      cra,
      ldw: this._ldw(),
      proposedLdw: this._ldw(true),
      earlyPurchase: this._earlyPurchase()
    }
  }

  _totalPaid(down) {
    return (this._monthlyRental(down).multiply(this.months).add(this._cra(down)))
  }

  _depositNeeded() {
    return this.depositRequired
  }

  _depositMultiplier() {
    if (this._depositNeeded()) {
      return 2
    }

    return 1
  }

  _cra(down = undefined) {
    if (!down || this._initial().intValue > down.intValue) {
      return currency(0)
    }

    const multiplier = this._depositMultiplier() * this._taxMultiplier()

    const initial = this._initial()

    const theoreticalCRA = (
      down.subtract(initial)
    )

    const increment = this._depositMultiplier() * (this._divisor() / this._taxMultiplier())

    const mod = theoreticalCRA.intValue % increment

    const finalCRA = theoreticalCRA.intValue - Math.round(mod)

    return currency(finalCRA / 100).divide(
      1 - multiplier / this._divisor()
    )
  }

  _uncovered(down = undefined) {
    return this.price.subtract(this._cra(down))
  }

  _monthlyRental(down = undefined) {
    return this._uncovered(down).divide(this._divisor())
  }

  _monthly(down = undefined) {
    return this._monthlyRental(down).add(this._ldw()).add(this._monthlySalesTax(down))
  }

  _monthlySalesTax(down) {
    return this._monthlyRental(down).add(this._ldw()).multiply(this.taxRate)
  }

  _initialSalesTax(down) {
    return this._monthlyRental(down).add(this._ldw()).add(this.setupFee).multiply(this.taxRate)
  }

  _securityDeposit(down = undefined) {
    if (this._depositNeeded()) {
      return this._monthly(down)
    }
    return currency(0)
  }

  _initial(down = undefined) {
    return this._monthly(down).add(this._securityDeposit(down))
      .add(this._cra(down))
      .add(this.deliveryCharge)
      .add(this.setupFee.multiply(this._taxMultiplier()))
  }

  _ldw(requested = this.ldwRequested) {
    if (!requested) {
      return currency(0.0)
    }

    if (this.price.value < 3000) {
      return currency(5.0)
    }

    if (this.price.value < 10000) {
      return currency(8.0)
    }

    return currency(15.0)
  }

  _earlyPurchase() {
    if (this.state === 'SC') return 55

    return {
      24: 70,
      36: 60,
      48: 50,
      54: 50,
      60: 45
    }[this.months]
  }

  _divisor() {
    return {
      24: 16.8,
      36: 21.6,
      48: 24,
      54: 27,
      60: 27
    }[this.months]
  }

  _taxMultiplier() {
    return 1 + this.taxRate
  }
}
