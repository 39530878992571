import { Controller } from '@hotwired/stimulus'

// Based on code from https://mentalized.net/journal/2020/11/30/upload-multiple-files-with-rails/
export default class extends Controller {
  static values = { inputName: String }
  static targets = ['photoList']

  upload(event) {
    const originalInput = event.target
    const originalParent = originalInput.parentNode

    this._addPreview(originalInput)

    this._addFileInput(originalInput, originalParent)

    this.photoListTarget.removeChild(originalParent)
  }

  _addPreview(originalInput) {
    Array.from(originalInput.files).forEach(file => {
      const reader = new FileReader()
      const image = document.createElement('img')
      const container = this.newPhotoContainer

      image.title = file.name

      reader.onload = function () {
        image.src = reader.result
      }
      reader.readAsDataURL(file)

      container.appendChild(image)
      container.insertAdjacentHTML('beforeEnd', this.newRemoveButton)

      this.photoListTarget.appendChild(container)
      this.photoListTarget.appendChild(originalInput)
    })
  }

  _addFileInput(originalInput, originalParent) {
    const labelNode = this._createNewLabel(originalParent)
    const newInput = originalInput.cloneNode()

    // Clear the filelist - some browsers maintain the filelist when cloning, others don't
    newInput.value = ''

    const idNumber = this.photoListTarget.childNodes.length - 1
    const newId = `${this.inputNameValue}_${idNumber}`
    newInput.id = newId
    labelNode.htmlFor = newId

    labelNode.append(newInput)
    this.photoListTarget.append(labelNode)
  }

  _createNewLabel(originalParent) {
    const labelNode = originalParent.cloneNode()

    labelNode.classList.add('flex', 'flex-col', 'items-center')

    const icon = document.createElement('span')
    icon.innerText = 'add'
    icon.classList.add('material-icons')

    const textContent = document.createTextNode('Add Photo')

    labelNode.append(icon)
    labelNode.append(textContent)

    return labelNode
  }

  get newPhotoContainer() {
    const el = document.createElement('div')
    el.classList.add('photo-tray__photo')
    el.dataset.controller = 'remove-upload'
    return el
  }

  get newRemoveButton() {
    return `
      <button class="photo-tray__remove" data-action="click->remove-upload#perform">
        <span class="material-icons " style="color: var(--color-)">close</span>
      </button>`
  }
}
