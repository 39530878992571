import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'basePriceInput',
    'originalPriceInput',
    'resalePriceInput',

    'lineItemDescriptionInput',
    'lineItemPriceInput',
    'lineItemQuantityInput',
  ]

  connect() {
    this.change()
  }

  lineItemDescriptionInputTargetDisconnected(_element) {
    this.change()
  }

  change(_event) {
    this.validateFields()
    this.updateCalculations()
  }

  validateFields() {
    const validate = (element) => {
      const value = parseFloat(element.value) || 0.00
      if (value < 0.0) {
        element.value = ''
      }
    }

    if (this.hasBasePriceInputTarget) validate(this.basePriceInputTarget)
    if (this.hasOriginalPriceInputTarget) validate(this.originalPriceInputTarget)
    if (this.hasResalePriceInputTarget) validate(this.resalePriceInputTarget)
  }

  updateCalculations() {
    if (this.hasResalePriceInputTarget) {
      this.basePriceInputTarget.value = this.originalPriceInputTarget.value
    } else {
      this.originalPriceInputTarget.value = this.calculateOriginalPrice().toString()
    }
  }

  calculateOriginalPrice() {
    const basePrice = parseFloat(this.basePriceInputTarget.value) || 0.0

    const lineItemsPrice = this.lineItemQuantityInputTargets.reduce((total, quantity, i) => {
      const lineItemTotal = parseInt(quantity.value, 10) * parseFloat(this.lineItemPriceInputTargets[i].value)
      return total + (lineItemTotal || 0.00)
    }, 0.0)

    return (basePrice + lineItemsPrice).toFixed(2)
  }
}
