import TomSelect from 'tom-select/dist/esm/tom-select.complete'
import TomSelectController from './tom_select_controller'

// https://github.com/orchidjs/tom-select/pull/449
// NOTE: this plugin was rejected by core maintainers
const limitDisplay = function (options) {
  const self = this

  let limit = options.limit ?? 5
  if (limit < 1) limit = 5

  const createCounterEl = (id, count) => {
    const counter = document.createElement('div')
    counter.id = id
    counter.className = 'item'
    counter.textContent = `${count} items selected`

    return counter
  }

  const updateDisplay = () => {
    const el = self.input
    const counterElId = `${el.id}_counter`
    const existingCounterEl = document.querySelector(`#${counterElId}`)
    if (self.items.length > limit) {
      self.control.querySelectorAll('.item').forEach(itemEl => {
        itemEl.hidden = true
      })
      const newCounterEl = createCounterEl(counterElId, self.items.length)
      if (existingCounterEl) {
        self.control.replaceChild(newCounterEl, existingCounterEl)
      } else {
        self.control.append(newCounterEl)
      }
    } else {
      if (existingCounterEl) self.control.removeChild(existingCounterEl)
      self.control.querySelectorAll('.item').forEach(itemEl => {
        itemEl.hidden = false
      })
    }
  }

  self.on('initialize', () => {
    const control = self.control_input

    updateDisplay()
    self.on('update item_add item_remove clear', updateDisplay)
    const controlActions = ['input', 'keyup', 'blur', 'update']
    controlActions.forEach(type => {
      control.addEventListener(type, updateDisplay)
    })
  })
}

TomSelect.define('limit_display', limitDisplay)

export default class extends TomSelectController {
  connect() {
    const plugins = {
      'dropdown_input': {},
      'checkbox_options': {},
      'limit_display': {
        limit: 2,
      },
      'caret_position': {},
      'clear_button': {},
    }
    const config = {
      create: false,
      maxItems: parseInt(this.data.get('count'), 10) || 1,
      plugins,
    }

    if (this.bodyDropdownValue) {
      config.dropdownParent = 'body'
    }
    // eslint-disable-next-line no-new
    this._tomSelect = new TomSelect(`#${this.dropdownTarget.id}`, config)
    this._tomSelect.positionDropdown()
  }
}
