import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'container',
  ]

  hide(event) {
    event.preventDefault()
    this.containerTarget.classList.add('hidden')
  }

  show(event) {
    event.preventDefault()
    this.containerTarget.classList.remove('hidden')
  }
}
