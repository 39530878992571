import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'label',
    'input',
  ]

  uploadReceipt() {
    const file = this.inputTarget.files[0]
    const reader = new FileReader()
    const image = document.createElement('img')
    const container = this.imageContainer

    image.title = file.name
    reader.onload = function () {
      image.src = reader.result
    }
    reader.readAsDataURL(file)

    this.labelTarget.innerHTML = file.name
    container.appendChild(image)
    this.labelTarget.prepend(container)
  }

  get imageContainer() {
    const element = document.createElement('div')
    element.classList.add('delivery-receipt__preview')
    return element
  }
}
