import { Controller } from '@hotwired/stimulus'
import SignaturePad from 'signature_pad'
import trimCanvas from 'trim-canvas/index.es6'

export default class extends Controller {
  static targets = ['pad', 'hidden']

  saveSignature() {
    this.hiddenTarget.value = this.trimCanvas().toDataURL('image/png')
  }

  connect() {
    const ratio = Math.max(window.devicePixelRatio || 1, 1)
    const canvas = this.padTarget
    canvas.width = canvas.offsetWidth * ratio
    canvas.height = canvas.offsetHeight * ratio
    canvas.getContext('2d').scale(ratio, ratio)
    this.signaturePad = new SignaturePad(canvas, {
      onEnd: this.saveSignature.bind(this)
    })
    if (this.hiddenTarget.value) {
      this.signaturePad.fromDataURL(this.hiddenTarget.value)
    }
  }

  // adapted from react-signature-canvas under Apache 2.0 license
  //  https://github.com/agilgur5/react-signature-canvas/blob/310bff81813509a4035bedfe50d76e7045a880cb/src/index.js
  trimCanvas() {
    // copy the canvas
    const copy = document.createElement('canvas')
    copy.width = this.padTarget.width
    copy.height = this.padTarget.height
    copy.getContext('2d').drawImage(this.padTarget, 0, 0)
    // then trim it
    return trimCanvas(copy)
  }

  disconnect() {
    this.signaturePad.off()
  }
}
