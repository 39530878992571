import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'firstNameInput',
    'lastNameInput',
    'primaryPhoneInput',
    'emailInput',
    'customerInput',
    'addressLine1Input',
    'addressLine2Input',
    'addressCountyInput',
    'addressCityInput',
    'addressStateInput',
    'addressPostalCodeInput',
    'addressCountryInput',
    'submit',
  ]

  load() {
    if (!this.customerInputTarget.value) return this.updateInputs(this.blankCustomer)

    const url = `/api/customers/${this.customerInputTarget.value}`

    fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      credentials: 'same-origin',
    }).then(response => (
      response.json()
    )).then(customer => {
      this.updateInputs(customer)
    })
  }

  updateInputs(customer) {
    this.firstNameInputTarget.value = customer.first_name
    this.lastNameInputTarget.value = customer.last_name
    this.primaryPhoneInputTarget.value = customer.phone
    this.updateEmail(customer.email || '')
    if (customer.hasOwnProperty('address_line_1')) {
      this.addressLine1InputTarget.value = customer.address_line_1
      this.addressLine2InputTarget.value = customer.address_line_2
      this.addressCityInputTarget.value = customer.city
      this.addressCountyInputTarget.value = customer.county
      this.addressStateInputTarget.value = customer.state_province
      this.addressPostalCodeInputTarget.value = customer.postal_code
      this.addressCountryInputTarget.value = customer.country_code

      this.addressStateInputTarget.dispatchEvent(new Event('change', { bubbles: true }))
    }
  }

  updateEmail(eventOrEmail) {
    const emailValue = eventOrEmail.target ? eventOrEmail.target.value : eventOrEmail
    this.emailInputTarget.value = emailValue

    if (this.hasSubmitTarget) { this.submitTarget.disabled = emailValue === '' }
  }

  get blankCustomer() {
    return {
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      address_line_1: '',
      address_line_2: '',
      city: '',
      county: '',
      state_province: '',
      postal_code: '',
      country_code: '',
    }
  }
}
