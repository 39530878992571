import React from 'react'
import PropTypes from 'prop-types'
import { Loader } from '@googlemaps/js-api-loader'

export default class Map extends React.Component {
  static propTypes = {
    records: PropTypes.array.isRequired
  }

  componentDidMount() {
    this.buildMap()
  }

  get coordinates() {
    this._coordinates ||= this.props.records.map(record => {
      return Object.assign({}, record, {
        lat: Number(record.lat),
        lng: Number(record.lng)
      })
    })

    return this._coordinates
  }

  get mapDimensions() {
    return {
      height: this.map.offsetHeight,
      width: this.map.offsetWidth
    }
  }

  get map() {
    return document.getElementById('map')
  }

  get bounds() {
    const boundsCoordinates = this.coordinates.reduce((target, coordinates) => {
      target.southwest.lat = Math.min(target.southwest.lat, coordinates.lat)
      target.southwest.lng = Math.min(target.southwest.lng, coordinates.lng)

      target.northeast.lat = Math.max(target.northeast.lat, coordinates.lat)
      target.northeast.lng = Math.max(target.northeast.lng, coordinates.lng)

      return target
    }, { southwest: { lat: 90, lng: 180 }, northeast: { lat: -90, lng: -180 } })

    this._bounds ||= new google.maps.LatLngBounds(
      new google.maps.LatLng(boundsCoordinates.southwest.lat, boundsCoordinates.southwest.lng),
      new google.maps.LatLng(boundsCoordinates.northeast.lat, boundsCoordinates.northeast.lng)
    )

    return this._bounds
  }

  get center() {
    if (this.coordinates.length == 0) return { lat: 39.8283, lng: -98.5795 } // Geographic center of the contiguous US

    const center = this.bounds.getCenter()
    const formattedCenter = Object.entries(center).map(([key, value]) => (
      [key, value()]
    ))

    return Object.fromEntries(formattedCenter)
  }

  get boundsZoomLevel() {
    // Mercator projection transformation: https://stackoverflow.com/a/13274361
    const latRad = (lat) => {
      const sin = Math.sin(lat * Math.PI / 180)
      const radX2 = Math.log((1 + sin) / (1 - sin)) / 2
      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2
    }

    const zoom = (mapPx, worldPx, fraction) => {
      return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2)
    }

    const usDimensions = { height: 25, width: 25 }
    const defaultLatZoom = zoom(this.mapDimensions.height, usDimensions.height, 1)
    const defaultLngZoom = zoom(this.mapDimensions.width, usDimensions.width, 1)
    const defaultZoomLevel = Math.min(defaultLatZoom, defaultLngZoom)

    const worldDimensions = { height: 256, width: 256 }
    const maxZoomLevel = 11
    const northeastBounds = this.bounds.getNorthEast()
    const southwestBounds = this.bounds.getSouthWest()

    const latFraction = (latRad(northeastBounds.lat()) - latRad(southwestBounds.lat())) / Math.PI

    const lngDiff = northeastBounds.lng() - southwestBounds.lng()
    const lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360

    const latZoom = zoom(this.mapDimensions.height, worldDimensions.height, latFraction)
    const lngZoom = zoom(this.mapDimensions.width, worldDimensions.width, lngFraction)

    return Math.min(latZoom, lngZoom, maxZoomLevel) || defaultZoomLevel
  }

  link(record) {
    return `
      <div class='flex flex-col text-center'>
        <a href='${record.path}' class='flex flex-col items-center'>
          ${record.address}
        </a>
        <span>
          ${record.structureSize} - ${record.amount} - ${record.date || 'N/A'}
        </span>
      </div>
    `
  }

  buildMap() {
    const loader = new Loader({
      apiKey: process.env.GOOGLE_MAPS_API_KEY
    })

    loader.load().then(() => {
      const map = new google.maps.Map(document.getElementById('map'), {
        center: this.center,
        zoom: this.boundsZoomLevel
      })

      const infoWindow = new google.maps.InfoWindow()

      this.coordinates.forEach(position => {
        const marker = new google.maps.Marker({
          position,
          map
        })

        marker.addListener('click', () => {
          infoWindow.close()
          infoWindow.setContent(this.link(position))
          infoWindow.open(map, marker)
        })
      })
    })
  }

  render() {
    return <div id="map" />
  }
}
