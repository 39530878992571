import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select/dist/esm/tom-select.complete'

export default class extends Controller {
  static targets = ['dropdown']
  static values = {
    bodyDropdown: Boolean,
  }

  connect() {
    const plugins = {
      'dropdown_input': {},
      'caret_position': {},
    }
    const config = {
      create: false,
      maxItems: parseInt(this.data.get('count'), 10) || 1,
      plugins,
    }

    if (this.bodyDropdownValue) {
      config.dropdownParent = 'body'
    }
    // eslint-disable-next-line no-new
    this._tomSelect = new TomSelect(`#${this.dropdownTarget.id}`, config)
    this._tomSelect.positionDropdown()
  }

  disconnect() {
    this._tomSelect.destroy()
    this._tomSelect = undefined
  }
}
