import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['element']
  static values = {
    activeClass: { type: String, default: 'hidden' },
    performOnConnect: { type: Boolean, default: false },
  }

  connect() {
    if (this.performOnConnectValue) this.perform()
  }

  perform() {
    this.elementTarget.classList.toggle(this.activeClassValue)
  }
}
